import React, { useContext, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { IoCartOutline } from "react-icons/io5"
import classNames from "classnames"
import * as Sentry from "@sentry/react"

import { getEffectiveAvailableQuantity } from "../utils/cart-properties"
import {
  productIsSoldOut,
  getProductVariantAvailableQuantity,
  getProductVariantPrice,
} from "../utils/product-properties"

import { DrawerContext } from "../utils/drawer-context"
import { StoreContext } from "../utils/store-context"

import LocalizedLink from "../components/LocalizedLink"
import QuantitySelector from "../components/QuantitySelector"

const ProductMeta = props => {
  // props
  const {
    product,
    title,
    variantId,
    handleVariantChange,
    quantity,
    setQuantity,
    handleQuantityAddClick,
    handleQuantityRemoveClick,
  } = props

  // hooks
  const { formatMessage, formatNumber } = useIntl()
  const [addingToCart, setAddingToCart] = useState(false)
  const { addVariantToCart, checkout } = useContext(StoreContext)
  const { setIsDrawerOpen, setDrawerContent } = useContext(DrawerContext)

  const addToCart = async variantId => {
    setAddingToCart(true)

    try {
      await addVariantToCart(variantId, quantity).then(() => {
        setAddingToCart(false)
        setQuantity(1)
      })
    } catch (e) {
      Sentry.captureException(e)
      setIsDrawerOpen(true)
      setDrawerContent(
        <div className="has-text-danger">
          <FormattedMessage id={"general.error.add-to-cart"} />
        </div>
      )
      setTimeout(() => setIsDrawerOpen(false), 10000)
      setAddingToCart(false)
    }
  }

  const cartItems = checkout?.lineItems || []

  // button classes
  const addToCartButtonClasses = classNames({
    button: true,
    "is-primary": !productIsSoldOut(product),
    "is-loading": addingToCart,
  })

  // condition for a disabled add to cart operation
  // - it’s sold out OR
  // - in case it’s not a gift card: the effective available quantity is 0 OR
  // - an equal operation is already in progress
  const addToCartIsDisabled =
    productIsSoldOut(product) ||
    (product &&
      !product.isGiftCard &&
      getEffectiveAvailableQuantity(product, cartItems, variantId) === 0) ||
    addingToCart

  const variantLabel =
    product && product.variants.length > 1
      ? product.options?.map(o => o.name).join(" / ")
      : formatMessage({ id: "general.variant" })

  return (
    <React.Fragment>
      {/* Title */}
      <h1 className="title is-spaced">{title}</h1>
      {/* Price */}
      <p>
        <span className="is-size-5">
          {formatNumber(getProductVariantPrice(product, variantId), {
            style: "currency",
            currency: "EUR",
          })}
        </span>
        <br />
        {/* NOTE: Not displayed as long as no VAT is calculated */}
        {/* {product?.isGiftCard === false && (
          <FormattedMessage
            id={"general.vat-included-other-costs-excluded"}
            values={{
              link: (
                <LocalizedLink to={"/shipping-and-return"}>
                  <FormattedMessage id={"general.shipping-costs"} />
                </LocalizedLink>
              ),
            }}
          />
        )} */}
        {product?.isGiftCard === false && (
          <FormattedMessage
            id={"general.other-costs-excluded"}
            values={{
              link: (
                <LocalizedLink to={"/shipping-and-return"}>
                  <FormattedMessage id={"general.shipping-costs"} />
                </LocalizedLink>
              ),
            }}
          />
        )}
      </p>
      {/* Variant Selector */}
      {product && product.variants.length > 1 && (
        <React.Fragment>
          <div className="is-block mb-2">{variantLabel}</div>
          <div className="select mb-4">
            <select onChange={handleVariantChange} value={variantId}>
              {product.variants.map(variant => (
                <option key={variant.id} value={variant.storefrontId}>
                  {variant.title} (
                  {formatNumber(
                    getProductVariantPrice(product, variant.storefrontId),
                    {
                      style: "currency",
                      currency: "EUR",
                    }
                  )}
                  {getProductVariantAvailableQuantity(
                    product,
                    variant.storefrontId
                  ) === 0
                    ? `, ${(
                      <FormattedMessage
                        id={"general.sold-out-variant-short"}
                      />
                    )}`
                    : ""}
                  )
                </option>
              ))}
            </select>
          </div>
        </React.Fragment>
      )}
      {/* Quantity Selector */}
      {!productIsSoldOut(product) && (
        <QuantitySelector
          quantity={quantity}
          inventory={getEffectiveAvailableQuantity(
            product,
            cartItems,
            variantId
          )}
          handleAddClick={handleQuantityAddClick}
          handleRemoveClick={handleQuantityRemoveClick}
          isDigitalProduct={product?.isGiftCard}
        />
      )}
      {productIsSoldOut(product) && (
        <p className="py-2">
          <FormattedMessage id={"general.sold-out"} />
        </p>
      )}
      {/* CTA */}
      {!productIsSoldOut(product) && (
        <button
          className={addToCartButtonClasses}
          disabled={addToCartIsDisabled}
          onClick={() => {
            if (!addToCartIsDisabled) addToCart(variantId)
          }}
          title={formatMessage({ id: "general.add-to-cart" })}
        >
          <span>
            <FormattedMessage id={"general.add-to-cart"} />
          </span>
          <span className="icon">
            <IoCartOutline />
          </span>
        </button>
      )}
    </React.Fragment>
  )
}

export default ProductMeta
