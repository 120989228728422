import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import { graphql } from "gatsby"
import { FormattedMessage, useIntl } from "react-intl"
import { getSrc, GatsbyImage } from "gatsby-plugin-image"
import { useQueryParam, StringParam, withDefault } from "use-query-params"
import Sticky from "react-stickynode"
import { INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"
import * as Sentry from "@sentry/react"

import Carousel, { slidesToShowPlugin } from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"

import Layout from "../layouts"
import { stickyTop } from "../config/main"

import { goToSlideByNumber, getCarouselPlugins } from "../utils/carousel-utils"
import { getShopifyContentfulMatcher } from "../utils/product-matcher"
import { getProductInventory } from "../utils/product-properties"
import { smartReplacements } from "../utils/text-properties"
import useWindowWidth from "../utils/window-width"

import ProductMeta from "../components/ProductMeta"
import ProductBox from "../components/ProductBox"
import LocalizedLink from "../components/LocalizedLink"

const ProductPage = props => {
  // props
  const {
    data: {
      contentfulProduct: contentful,
      shopifyProduct: product,
      allShopifyProductVariant: { nodes: relatedProductVariants },
      allContentfulProduct: { nodes: relatedProductVariantsContentful },
      allContentfulMenu: { nodes: menuCollections },
    },
    pageContext: { locale },
  } = props

  // hooks
  const { formatMessage } = useIntl()
  const location = useLocation()

  // smart replacements
  if (contentful) {
    contentful.productTitle = smartReplacements(contentful.productTitle, locale)
  }

  // fetch inventory for product
  const [loading, setLoading] = useState(true)
  const [inventory, setInventory] = useState({
    totalInventory: 0,
    variants: [],
  })
  let enhancedProduct = {
    ...product,
    title: smartReplacements(product.title, locale),
    totalInventory: inventory.totalInventory,
    variants: product.variants.map(variant => {
      const matchingVariant = inventory.variants.find(
        v => v.id === variant.storefrontId
      )
      if (!matchingVariant && inventory.totalInventory > 0) {
        Sentry.captureException(
          new Error(`No matching variant found for ${variant.storefrontId} (${variant.storefrontId}) "${product.title}" at ${location.pathname}`)
        )
      }

      return {
        ...variant,
        inventoryQuantity: matchingVariant
          ? matchingVariant.quantityAvailable
          : 0,
      }
    }),
  }

  useEffect(() => {
    if (product.storefrontId) {
      getProductInventory(product.storefrontId)
        .then(setInventory)
        .finally(() => setLoading(false))
    }
  }, [product.storefrontId])

  /**
   * get related product meta data if available
   * @param {Object} product
   * @param {String} locale
   * @returns
   */
  const getRelatedProductMetaData = (product, locale) => {
    return relatedProductVariantsContentful.find(c => {
      return (
        product.variants.some(
          v => getShopifyContentfulMatcher(v, c)
        ) && c.node_locale === locale
      )
    })
  }

  // query params:
  //   - v {String} variant ID
  const [variantId, setVariantId] = useQueryParam(
    "v",
    withDefault(StringParam, enhancedProduct?.variants[0].storefrontId || null)
  )

  // hooks
  const [quantity, setQuantity] = useState(1)

  const handleVariantChange = e => {
    setVariantId(e.target.value)
  }

  const handleQuantityAddClick = () => {
    setQuantity(quantity + 1)
  }

  const handleQuantityRemoveClick = () => {
    setQuantity(quantity - 1)
  }

  // get main collections (the ones that appear in the main navigation)
  const mainCollections = menuCollections[0].menuEntries.reduce((acc, cur) => {
    if (cur.shopifyCollectionStorefrontId)
      acc.push(cur.shopifyCollectionStorefrontId)
    return acc
  }, [])

  // determine active collection (storefrontId)
  let activeCollection = null
  mainCollections.forEach(collection => {
    if (
      enhancedProduct &&
      enhancedProduct.collections.some(c => c.storefrontId === collection)
    ) {
      activeCollection = collection
    }
  })

  // see: https://www.contentful.com/developers/docs/tutorials/general/rich-text-and-gatsby/
  // and: https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer
  /**
   * The `renderNode` keys should be one of the following `BLOCKS` and `INLINES` properties:
   *    - BLOCKS
   *      - DOCUMENT
   *      - PARAGRAPH
   *      - HEADING_1 ... HEADING_6
   *      - UL_LIST, OL_LIST, LIST_ITEM
   *      - QUOTE
   *      - HR
   *      - EMBEDDED_ENTRY, EMBEDDED_ASSET
   *    - INLINES
   *      - EMBEDDED_ENTRY
   *      - HYPERLINK, ENTRY_HYPERLINK, ASSET_HYPERLINK
   *
   * The `renderMark` keys should be one of the following `MARKS` properties:
   *    - BOLD, ITALIC, UNDERLINE, CODE
   *
   * The `renderText` callback is a function that has a single string argument and returns a
   * React node. Each text node is evaluated individually by this callback. A possible use case
   * for this is to replace instances of `\n` produced by `Shift + Enter` with `<br />` React
   * elements.
   */
  const renderOptions = {
    renderNode: {
      [INLINES.ENTRY_HYPERLINK]: node => {
        return (
          <LocalizedLink to={`/${node.data.target.pageSlug}`}>
            {node.content[0].value}
          </LocalizedLink>
        )
      },
    },
    renderText: text => {
      return smartReplacements(text, locale)
        .split("\n")
        .reduce((children, textSegment, index) => {
          return [...children, index > 0 && <br key={index} />, textSegment]
        }, [])
    },
  }

  // get social share image with `getSrc` helper method from Gatsby Image plugin API
  const socialShareImage = enhancedProduct?.images[0]
    ? getSrc(enhancedProduct?.images[0])
    : null

  // get localized values
  const seoDescriptionText = contentful?.seoDescription
    ? documentToPlainTextString(JSON.parse(contentful.seoDescription.raw))
    : contentful?.productTitle

  // carousel
  const [currentSlide, setCurrentSlide] = useState(0)
  const carouselPlugins = [
    "fastSwipe",
    {
      resolve: slidesToShowPlugin,
      options: { numberOfSlides: 1 },
    },
  ]

  // carousel (multiple)
  const [currentSlides, setCurrentSlides] = useState([0, 0])

  const width = useWindowWidth()
  let numberOfSlidesShown = 5
  let stickyEnabled = false
  if (width <= 1216) {
    numberOfSlidesShown = 4
    stickyEnabled = true
  }
  if (width <= 1024) {
    numberOfSlidesShown = 3
    stickyEnabled = true
  }
  if (width <= 768) {
    numberOfSlidesShown = 2
    stickyEnabled = false
  }
  if (width <= 480) {
    numberOfSlidesShown = 1
    stickyEnabled = false
  }

  const getNavButton = (dir = 1, max) => {
    return (dir === -1 && currentSlide > 0) ||
      (dir === 1 && currentSlide < max - 1)
      ? (
        <button
          className="button is-ghost"
          onClick={() => setCurrentSlide(currentSlide + dir)}
        >
          <small>{dir === -1 ? "⟵" : "⟶"}</small>
        </button>
      )
      : (
        <button
          className="button is-ghost"
          onClick={() => setCurrentSlide(currentSlide + dir)}
          disabled
        >
          <small>{dir === -1 ? "⟵" : "⟶"}</small>
        </button>
      )
  }

  // sort related products according to order in Contentful
  const relatedProductVariantsSorted =
    [...relatedProductVariants].map((p, i) => {
      return relatedProductVariants.find(rp => {
        return getShopifyContentfulMatcher(rp, contentful.relatedProducts[i])
      })
    })

  return (
    <Layout
      title={
        contentful?.seoTitle ||
        contentful?.productTitle ||
        enhancedProduct?.title
      }
      description={seoDescriptionText || enhancedProduct?.description || ""}
      metatags={contentful?.seoMetatags}
      image={socialShareImage}
      activeCollection={activeCollection}
    >
      <section className="section py-5" />

      <section className="section pt-5 mb-6" id="content">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-hidden-tablet">
              {!loading && (
                <ProductMeta
                  area={enhancedProduct?.id || null}
                  product={enhancedProduct}
                  title={
                    contentful?.productTitle || enhancedProduct?.title || ""
                  }
                  variantId={variantId}
                  handleVariantChange={handleVariantChange}
                  quantity={quantity}
                  setQuantity={setQuantity}
                  handleQuantityAddClick={handleQuantityAddClick}
                  handleQuantityRemoveClick={handleQuantityRemoveClick}
                />
              )}
            </div>
            {/* Gallery */}
            <div className="column is-6">
              {product?.images.length > 0 && (
                <Sticky top={stickyTop} bottomBoundary="#content" enabled={stickyEnabled}>
                  <div className="columns">
                    <div
                      className="column is-narrow is-hidden-touch"
                      style={{ width: 100 }}
                    >
                      {/* Thumbnails */}
                      {enhancedProduct &&
                        enhancedProduct.images.map((image, index) => (
                          <figure
                            key={index}
                            className={`image mb-3 thumbnail${currentSlide === index ? " is-active" : ""}`}
                          >
                            <GatsbyImage
                              objectFit="contain"
                              loading={index === 0 ? "eager" : "lazy"}
                              alt={image.altText || enhancedProduct.title}
                              image={image.gatsbyImageData}
                              className={`is-clickable${currentSlide === index ? " is-active" : ""}`}
                              onClick={() => setCurrentSlide(index)}
                            />
                          </figure>
                        ))}
                    </div>
                    <div className="column is-clipped">
                      {/* Big Images */}
                      {typeof window !== "undefined" && Carousel && <Carousel
                        onChange={index => {
                          // normalize boundaries
                          if (!isNaN(index)) {
                            index = Math.max(0, index)
                            index = Math.min(
                              enhancedProduct.images.length - 1,
                              index
                            )
                            setCurrentSlide(index)
                          }
                        }}
                        plugins={carouselPlugins}
                        value={currentSlide}
                      >
                        {enhancedProduct?.images.map((image, index) => (
                          <figure className="image zoom" key={index}>
                            <GatsbyImage
                              objectFit="contain"
                              loading={index === 0 ? "eager" : "lazy"}
                              alt={
                                image.altText ||
                                contentful?.productTitle ||
                                enhancedProduct?.title
                              }
                              image={image.gatsbyImageData}
                            />
                          </figure>
                        ))}
                      </Carousel>}
                      <nav
                        className="pagination is-centered py-2"
                        role="navigation"
                        aria-label="pagination"
                      >
                        <ul className="pagination-list">
                          <li>
                            {getNavButton(-1, enhancedProduct?.images.length)}
                          </li>
                          <li className="px-4">
                            <small>
                              {currentSlide + 1} /{" "}
                              {enhancedProduct?.images.length}
                            </small>
                          </li>
                          <li>
                            {getNavButton(1, enhancedProduct?.images.length)}
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </Sticky>
              )}
            </div>
            <div className="column is-6 pl-3-tablet-only pl-6-desktop">
              <div className="is-hidden-mobile">
                {!loading && (
                  <ProductMeta
                    area={enhancedProduct?.id || null}
                    product={enhancedProduct}
                    title={
                      contentful?.productTitle || enhancedProduct?.title || ""
                    }
                    variantId={variantId}
                    handleVariantChange={handleVariantChange}
                    quantity={quantity}
                    setQuantity={setQuantity}
                    handleQuantityAddClick={handleQuantityAddClick}
                    handleQuantityRemoveClick={handleQuantityRemoveClick}
                  />
                )}
              </div>
              {/* Description */}
              <div className="content mt-6">
                {contentful?.productDescription &&
                  renderRichText(contentful?.productDescription, renderOptions)}
              </div>
              {/* Tax note */}
              <div className="mt-6">
                <small>
                  <FormattedMessage id={"general.tax-note"} />
                </small>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Related Products */}
      {relatedProductVariantsSorted.length > 0 && (
        <section className="section has-background-paper">
          <div className="container">
            <h2 className="subtitle">
              <FormattedMessage id={"general.product.related-products"} />
            </h2>
            {typeof window !== "undefined" && Carousel && <Carousel
              plugins={getCarouselPlugins(
                currentSlides,
                setCurrentSlides,
                0,
                relatedProductVariantsSorted.length,
                numberOfSlidesShown,
                formatMessage({ id: "general.previous" }),
                formatMessage({ id: "general.next" })
              )}
              value={currentSlides[0]}
              onChange={index =>
                goToSlideByNumber(
                  currentSlides,
                  setCurrentSlides,
                  0,
                  relatedProductVariantsSorted.length,
                  numberOfSlidesShown,
                  index
                )
              }
            >
              {relatedProductVariantsSorted.map(relatedProductVariant => (
                <ProductBox
                  area={relatedProductVariant.product.id}
                  key={relatedProductVariant.product.id}
                  product={{
                    ...relatedProductVariant.product,
                    handle:
                      getRelatedProductMetaData(
                        relatedProductVariant.product,
                        locale
                      )?.productSlug || relatedProductVariant.product.handle,
                    title:
                      getRelatedProductMetaData(
                        relatedProductVariant.product,
                        locale
                      )?.productTitle || relatedProductVariant.product.title,
                  }}
                  locale={locale}
                  onPaper={true}
                />
              ))}
            </Carousel>}
          </div>
        </section>
      )}

      {/* Related Posts */}
      {/* TODO: populate posts with required data */}
      {/* {contentful?.relatedPosts && (
        <section className="section">
          <div className="container">
            <h2 className="subtitle">
              <FormattedMessage id={"general.product.relevant-posts"} />
            </h2>
            {typeof window !== "undefined" && Carousel && <Carousel
              plugins={getCarouselPlugins(
                currentSlides,
                setCurrentSlides,
                1,
                contentful.relatedPosts.length,
                numberOfSlidesShown,,
                formatMessage({ id: "general.previous" }),
                formatMessage({ id: "general.next" })
              )}
              value={currentSlides[1]}
              onChange={index => goToSlideByNumber(
                currentSlides,
                setCurrentSlides,
                1,
                contentful.relatedPosts.length,
                numberOfSlidesShown,
                index,
              )}
            >
              {contentful.relatedPosts.map(post => (
                <PostBox
                  key={post.id}
                  post={post}
                  asColumn={false}
                  size="px-2"
                />
              ))}
            </Carousel>}
          </div>
        </section>
      )} */}

      {/* <pre>{JSON.stringify(product, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(enhancedProduct, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(contentful, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(relatedProductVariantIds, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(relatedProductVariantIdsBase64, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(relatedProductVariants.map(v => v.product.handle), null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(relatedProductVariantsContentful, null, 2)}</pre> */}
    </Layout>
  )
}

export default ProductPage

export const ProductPageQuery = graphql`
  query (
    $contentfulId: String
    $productId: String!
    $locale: String!
    $relatedProductVariantIds: [String!]
    $relatedProductVariantIdsBase64: [String!]
  ) {
    contentfulProduct(
      contentful_id: { eq: $contentfulId }
      node_locale: { eq: $locale }
    ) {
      productTitle
      productDescription {
        raw
      }
      relatedProducts {
        productSlug
        productTitle
        shopifyProductVariantId
      }
      # relatedPosts {
      #   postSlug
      #   postTitle
      # }
      seoTitle
      seoDescription {
        raw
      }
      seoMetatags
    }
    allShopifyProductVariant(
      filter: { storefrontId: { in: $relatedProductVariantIds } }
    ) {
      nodes {
        storefrontId
        product {
          collections {
            id
            handle
            title
          }
          description
          descriptionHtml
          handle
          hasOnlyDefaultVariant
          hasOutOfStockVariants
          id
          images {
            altText
            id
            gatsbyImageData(
              layout: CONSTRAINED
              width: 400
              aspectRatio: 0.75
              breakpoints: [100, 200, 300, 400]
            )
          }
          isGiftCard
          options {
            id
            name
            values
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          productType
          storefrontId
          title
          totalVariants
          variants {
            availableForSale
            compareAtPrice
            id
            price
            storefrontId
            title
          }
        }
      }
    }
    allContentfulProduct(
      filter: { shopifyProductVariantId: { in: $relatedProductVariantIdsBase64 } }
    ) {
      nodes {
        node_locale
        productSlug
        productTitle
        shopifyProductVariantId
      }
    }
    shopifyProduct(id: { eq: $productId }) {
      collections {
        id
        handle
        storefrontId
        title
      }
      description
      descriptionHtml
      handle
      hasOnlyDefaultVariant
      hasOutOfStockVariants
      id
      images {
        altText
        id
        gatsbyImageData(
          layout: CONSTRAINED
          width: 400
          aspectRatio: 0.75
          breakpoints: [100, 200, 300, 400]
        )
      }
      isGiftCard
      options {
        id
        name
        values
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      productType
      storefrontId
      title
      totalVariants
      variants {
        availableForSale
        compareAtPrice
        id
        price
        storefrontId
        title
      }
    }
    allContentfulMenu(
      filter: { menuSlug: { eq: "main-navigation" }, node_locale: { eq: "de" } }
    ) {
      nodes {
        menuEntries {
          ... on ContentfulCollection {
            shopifyCollectionStorefrontId
          }
        }
      }
    }
  }
`
